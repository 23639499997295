if ($(window).width() > 640) {
    console.log('Supposed to animate');
    var topbar = $('.top-bar-section');
    var burgerNav = $('.burger-nav');
    var fullScreenNav = $('.full-screen-nav');
    var logo = $('.logo');


    $(window).scroll(function () {

        if($(window).scrollTop() > 360) {
            logo.fadeOut();
            topbar.addClass('animated bounceOutRight');
            burgerNav.removeClass('hide');
            burgerNav.addClass('animated bounceInRight');
        }

        if($(window).scrollTop() < 150) {
            $('.burger-nav').addClass('hide');
        }

        if($(window).scrollTop() == 0) {
            topbar.removeClass('animated bounceOutRight');
            logo.fadeIn();
        }
    });

    burgerNav.click(function(){
        burgerNav.addClass('hide');
        fullScreenNav.fadeIn();
        fullScreenNav.addClass('active');
    });

    $('.menu-item').click(function() {
        fullScreenNav.fadeOut();
    });

    $('.close-nav').click(function() {
        burgerNav.removeClass('hide');
        fullScreenNav.fadeOut();
    });
} else {
    console.log('NOT Supposed to animate');
}

if ($(window).width() < 640) {
    $(window).scroll(function () {

        if ($(window).scrollTop() > 360) {
            $('.top-bar').css({"background-color": "black"});
            $('header .overlay .contain-to-grid .top-bar ul li a').css({"color": "white"});
        }

        if($(window).scrollTop() == 0) {
            $('.top-bar').css({"background": "none"});
        }
    });
}